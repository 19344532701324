@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Space+Grotesk:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --font-1: 'Space Grotesk', sans-serif;
        --font-2: 'Inter', sans-serif;

        --primary-1: 227 193 119;	/* #E3C177 - Primary Main */
        --primary-2: 191 142 81;	/* #BF8E51 - Primary Focus */
        --primary-3: 251 247 237;	/* #FBF7ED - Primary Surface */
        --primary-4: 243 229 198;	/* #F3E5C6 - Primary Border */
        --background-1: 38 23 19;	/* #261713 - Background */
    }

    html {
        @apply scroll-smooth;
    }

    body {
        @apply font-theme-2 cursor-default;
    }

    header {
        @apply fixed top-0 z-10 w-screen;
    }

    main {
        @apply mt-20 lg:mt-24;
    }

    p {
        @apply [&:not(:last-child)]:mb-8;
    }
}

@layer components {
    .section {
        @apply max-w-[1920px] mx-auto px-4 lg:px-8;
    }

    .invert-white {
        filter: grayscale(1) contrast(100) brightness(1);
    }

    .brand {
        @apply w-[200px];
    }
    .navbar {
        @apply font-theme-1 lg:[&_li]:inline-block lg:[&_li]:mx-4 max-lg:[&_li]:my-10 max-lg:[&_a]:block max-lg:w-screen max-lg:h-screen max-lg:text-center max-lg:bg-black/90 max-lg:text-white max-lg:px-6 max-lg:-mx-4 max-lg:text-lg max-lg:[&_.button]:text-base;
    }

    .title {
        @apply relative inline-block font-theme-1 text-3xl lg:text-5xl font-bold;
    }
    .title:after {
        @apply content-[''] block w-full h-1 mt-2;
        background-image: linear-gradient(90deg, rgba(225, 181, 84, 0) 0%, rgba(225, 181, 84, 1) 50%, rgba(225, 181, 84, 0) 100%);
    }
    .title.gradient {
        @apply text-transparent bg-clip-text;
        background-image: radial-gradient(71.51% 305.41% at 19.35% 51.11%, #FFA800 0%, #FF2E00 100%);
    }

    .button {
        @apply px-5 py-3 text-sm text-center font-theme-1 font-bold shadow-lg transition-colors lg:min-w-[160px];
    }
    .button.primary {
        @apply text-white bg-primary-1 hover:bg-primary-2;
    }

    .pill {
        @apply [&:not([class*="selected"])]:cursor-pointer focus-visible:outline-none relative overflow-clip inline-block px-4 sm:px-7 py-3 mx-2 first:ml-0 last:mr-0 text-sm text-center font-theme-1 transition-colors border border-primary-1 bg-primary-3 text-primary-2 hover:bg-primary-1 hover:text-white;
    }
    .pill[class*="selected"] {
        @apply bg-primary-1 text-white;
    }
    .pill[class*="selected"]:before,
    .pill[class*="selected"]:after {
        @apply content-[''] absolute top-0 w-[75px] h-[50px] rotate-[287deg];
        background-image: linear-gradient(287deg, #E1B554 39.85%, rgba(255, 255, 255, 0) 97.88%);
    }
    .pill[class*="selected"]:before {
        @apply -left-11;
    }
    .pill[class*="selected"]:after {
        @apply -right-10;
    }

    .bg-gradient-welcome {
        background-image: linear-gradient(90deg, rgba(255, 226, 150, 0.22) 0%, rgba(72, 72, 72, 0) 100%);
    }
    .bg-gradient-footer {
        @apply opacity-40;
        background-image: linear-gradient(270deg, rgba(255, 226, 150, 0.22) 0%, rgba(72, 72, 72, 0) 100%);
    }

    .card-story {
        @apply flex items-start lg:items-center gap-12 max-lg:flex-wrap px-10;
    }
    .card-story .title {
        @apply relative inline-block font-theme-1 text-2xl lg:text-3xl font-bold after:hidden;
    }
    .card-story .title.gradient {
        background-image: radial-gradient(89.93% 389.79% at 80.69% 50%, #FFEDBE 0%, #FF2E00 100%);
    }
    .card-story .progress {
        @apply rounded-full bg-primary-2;
    }
    .card-story .progress .bar {
        @apply rounded-full bg-primary-4 h-1.5;
        box-shadow: 0 0 8px 0 rgba(255, 214, 0, .5);
    }
    .card-story .navigate {
        @apply flex gap-4 justify-between max-lg:absolute max-lg:bottom-0 max-lg:w-full;
    }
    .card-story .navigate-prev,
    .card-story .navigate-next {
        @apply bg-primary-1 hover:bg-primary-2 py-1.5 px-2 sm:py-2.5 sm:px-3 [&:not([disabled])]:cursor-pointer [&[disabled]]:bg-background-1 [&[disabled]]:pointer-events-none;
    }
    .card-story .navigate-prev {
        @apply scale-x-[-1];
    }

    .card-character {
        @apply bg-primary-4 p-4 relative overflow-clip;
    }
    .card-character:before,
    .card-character:after {
        @apply content-[''] absolute top-0 w-[200px] h-[100px] rotate-[300deg];
        background-image: linear-gradient(287deg, #FFD075 39.85%, rgba(255, 255, 255, 0) 97.88%);
    }
    .card-character:before {
        @apply -left-3;
    }
    .card-character:after {
        @apply -right-14 top-[initial] bottom-0 rotate-[120deg];
    }

    .card-benefit > *,
    .card-mission > * {
        @apply bg-white shadow-lg rounded-xl;
    }

    .img-crisp {
        image-rendering: -webkit-optimize-contrast;
        image-rendering: -moz-crisp-edges;
        image-rendering: -o-crisp-edges;
        image-rendering: crisp-edges;
    }

    .walking-1 {
        animation: walking1 1s linear infinite;
    }
    @keyframes walking1 {
        0% {
            opacity: 100%;
        }
        50% {
            opacity: 0%;
        }
        100% {
            opacity: 100%;
        }
    }
    .walking-2 {
        animation: walking2 1s linear infinite;
    }
    @keyframes walking2 {
        0% {
            opacity: 0%;
        }
        50% {
            opacity: 100%;
        }
        100% {
            opacity: 0%;
        }
    }
}

.splide__arrow {
    @apply !rounded-none !w-10 !h-10 !p-2 !opacity-100 shadow-lg transition-colors text-white !bg-primary-1 hover:!bg-primary-2 [&_svg]:!w-6 [&_svg]:!h-6 [&[disabled]]:shadow-none [&[disabled]]:cursor-default [&[disabled]]:text-primary-2 [&[disabled]]:!bg-primary-4;
}
.splide__pagination {
    @apply max-w-[50%] !mx-auto !mt-10 !relative !bottom-0 bg-primary-1/30 !flex-nowrap !p-0 rounded-full [&_li]:!flex [&_li]:w-full [&_li]:h-2;
}
.splide__pagination__page {
    @apply !opacity-100 !rounded-full !w-full !h-2 !m-0 !bg-transparent [&.is-active]:!scale-100 [&.is-active]:!bg-primary-1;
}
.splide__pagination__page.is-active {
    box-shadow: 0 0 8px 0 rgba(255, 214, 0, .5);
}
.splide__slide.is-active .card-character.focus,
.splide__slide.is-active .card-benefit.focus {
    @apply max-sm:scale-[.85] max-sm:transition-all;
}

#story .splide__arrow {
    @apply invisible;
}

#gallery .splide__arrows {
    @apply max-lg:flex max-lg:gap-4 max-lg:mx-6 max-lg:justify-between max-lg:mt-8;
}
#gallery .splide__arrow {
    @apply max-lg:!relative max-lg:!left-[initial] max-lg:!right-[initial] max-lg:!translate-y-[initial] lg:!top-[calc(50%-2.5rem)];
}
#gallery .splide__pagination {
    @apply max-lg:!absolute max-lg:!bottom-4;
}
/* #char-main .splide__list {
    @apply md:justify-center md:!ml-10;
} */

#benefit .splide__arrows {
    @apply flex gap-4 lg:absolute lg:-top-24 lg:right-6 max-lg:mx-6 max-lg:justify-between;
}
#benefit .splide__arrow {
    @apply !relative !left-[initial] !right-[initial] !translate-y-[initial];
}